<div class="members">
    <span class="txt">{{ cnt }}</span>
    <button class="button-ppl _icon" [matMenuTriggerFor]="menu">
        <mat-icon  class="icon" svgIcon="radius_two-people"></mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="more-menu">
        <div class="wrap members-menu">
            <div class="content">
                <div class="section-panel" *ngIf="leads.length">
                    <div class="header-panel">
                 <span class="txt">
                                Участники чата
                 </span>
                    </div>
                    <ng-container *ngFor="let item of leads">
                        <div class="user"
                             [ngClass]="{'_self': isMe(item.id)}">
                            <app-user-avatar [user]="item" class="member__img"></app-user-avatar>
                            <span class="txt">{{name(item)}}</span>

                            <mat-icon class="icon _notify"
                                      [ngClass]="{'_off': !item.notify}"
                                      (click)="onNotifyClick(isMe(item.id))"
                                      [matTooltip]="isMe(item.id) ? this.notify ? 'Отключаить уведомления': 'Включить уведомления' : 'Уведомления отключены'"
                                      [svgIcon]="item.notify ? 'radius_bell' : 'radius_bell_off'"></mat-icon>
                        </div>
                    </ng-container>
                </div>
                <div class="section-panel" *ngIf="guests.length">
                    <div class="header-panel">
                    <span class="txt">
                        Приглашенные в чат
                    </span>
                    </div>
                    <ng-container *ngFor="let item of guests">
                        <div class="user" [ngClass]="{'_self': isMe(item.id)}">
                            <app-user-avatar [user]="item" class="member__img"></app-user-avatar>
                            <span class="txt">{{name(item)}}</span>
                            <mat-icon class="icon _notify"
                                      *ngIf="isMe(item.id)"
                                      [ngClass]="{'_off': !this.notify}"
                                      (click)="onNotifyClick(isMe(item.id))"
                                      [svgIcon]="this.notify ? 'radius_bell' : 'radius_bell_off'"></mat-icon>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="footer">
                <a class="link more-menu__link" (click)="addUserClick()">
                    <mat-icon class="icon _add">person_add</mat-icon>
                    Добавить участника
                </a>

                <a class="link more-menu__link _grey" (click)="exitClick()" *ngIf="canLeave()">
                    <mat-icon class="icon _exit" svgIcon="radius_exit"></mat-icon>
                    Выйти из чата
                </a>
            </div>
        </div>
    </mat-menu>
</div>
