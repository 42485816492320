import {Component, Input} from '@angular/core';
import {ChatMessage} from '../../../../../models/chat';
import {ConfigService} from '../../../../../services/config.service';
import {getFileExtensionWidget} from '../../../../../models/file';

@Component({
  selector: 'app-chat-reply',
  templateUrl: './chat-reply.component.html',
  styleUrls: ['./chat-reply.component.scss']
})
export class ChatReplyComponent {
  @Input() reply: ChatMessage;

  constructor(
      public config: ConfigService
  ) {}

  getImageUrl(file) {
    return `${this.config.fileStoragePicsUrl}${file.name}/small`;
  }

  hasAttachments() {
    return this.reply && this.reply.attachments && this.reply.attachments.length;
  }

  isImage() {
    const item =  this.reply?.attachments[0];
    return item?.mime?.search('image') >= 0;
  }

  isAudio() {
    const item = this.reply?.attachments[0];
    if (item && item.mime && item.mime.includes('audio')) {
      return true;
    }

    return false;
  }

  isTask() {
    const item =  this.reply?.attachments[0];
    return item?.mime?.search('image') >= 0;
  }

  getFileUrl(item) {
      if (item) {
          return '/assets/file_extension/' + getFileExtensionWidget(item.name) + '.png';
      }
  }

  type() {
    if (this.isImage()) {
      return 'Фото'
    }

    if (this.isAudio()) {
      return 'Голосовое сообщение'
    }

    if (this.reply.links && this.reply.links[0]) {
      return this.reply.links[0].link_type == 'task' ? 'Ссылка на задачу №' + this.reply.links[0].entity_id : 'Ссылка';
    }

    return this.reply.attachments[0].fileName
  }

}
