import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {ConfigService} from './config.service';
import {OrgUnitShortForNotifications} from '../models/notificationCenter';
import {GlobalNotificationCenterService} from './global-notification-center.service';
import {JSON} from 'ta-json';
import {ChatMessage} from '../models/chat';
import {File} from '../models/file';
import {ApiService} from './api.service';
import {WebsocketService} from './webSocket.api';
import {AuthService} from './auth.service';
import {hashCode} from '../utils/commons';

@Injectable({
    providedIn: 'root'
})
export class ChatsService {

    public myCirclesIds$ = new BehaviorSubject<string[]>([]);
    public chatRooms$ = new BehaviorSubject(null);
    // Удалить чат
    deleteChat$ = new Subject();
    // Прочитать все сообщения
    readAll$ = new BehaviorSubject(null);
    // Получение счетчика новых сообщения для шапки (число непрочитанных чатов)
    chatsHeaderMessageCount$ = new BehaviorSubject(0);
    // Изменение в одном чат руме (новое сообщение/непрочитанные сообщения)
    chatsChatListItem$ = new BehaviorSubject(null);
    // Изменение в одном чат руме (непрочитанные сообщения)
    chatsChatLIstItemCounter$ = new BehaviorSubject(null);
    // Отправка хистори после иницизации чата
    sendHistoryMessages$ = new BehaviorSubject(null);
    // Отправка  времени последнего полученного сообщения в момент нахожддения в чате
    sendLastRead$ = new BehaviorSubject(null);
    // Отправка сообщения в чате
    sendChatMessage$ = new BehaviorSubject(null);
    // Получение сообщения в чате
    gotChatMessage$ = new BehaviorSubject(null);
    // Получение сообщения на редактирование
    editedChatMessage$ = new Subject();
    // Получение сообщения на удаление
    deletedChatMessage$ = new Subject();
    // Отписка от чата
    chatUnsubscribe$ = new BehaviorSubject(null);
    // Глобальное открытие чата
    openChat$ = new BehaviorSubject(null);
    // Галочки прочитанности сообщения
    chatMessageIsRead$ = new BehaviorSubject(null);
    // Обновить список чатов
    public getChats$ = new Subject;
    // Флаг состояния открытых чатрумов
    public opened$ = new BehaviorSubject(false);
    //  Id задачи для открытия из чатов
    public taskToOpenId$ = new BehaviorSubject(null);
    public openAttachedTaskFromChat$ = new BehaviorSubject(null);
    public reload$ = new Subject();
    public onAddUserClick$ = new Subject();
    public onLeaveChatClick$ = new Subject();
    public members$ = new BehaviorSubject(null);
    public notify$ = new BehaviorSubject(null);
    public onChatNotifyClick$ = new Subject();
    public taskToOpen$ = new Subject();
    public closeChat$ = new Subject();
    public updateNotify$ = new Subject();
    public attachmentsDraft$ = new BehaviorSubject(null);
    public makeFakeMsg$ = new Subject();
    public spliceLastMessage$ = new Subject();
    public resetUpload$ = new Subject();
    public updateChatRoom$ = new Subject();
    public stopAudio$ = new Subject();

    public replyMsg$ = new BehaviorSubject(null);
    public loaderPercent$ = new BehaviorSubject(null);;

    // file id downloading
    public downloadingFiles$ = new BehaviorSubject([]);

    constructor(
        readonly http: HttpClient,
        public config: ConfigService,
        private noti: GlobalNotificationCenterService,
        private api: ApiService,
        private notiService: GlobalNotificationCenterService,
        private wsService: WebsocketService,
        private auth: AuthService
    ) {
    }

    public getChatsRooms(api_key): Observable<any> {
        return this.http.get(
            `${this.config.chatRooms}`,
            {headers: {Authorization: api_key}}
        ).pipe(map(res => res['payload']));
    }

    public uploadFile(chatData) {
        this.api.uploadFileLoader(chatData.uploadData)
            .pipe(takeUntil(this.resetUpload$))
            .subscribe(res => {
                console.log(res);
                if (res['body'] && res['body']['payload']) {
                    this.loaderPercent$.next(null);
                    const file = JSON.deserialize<File>(res['body']['payload'], File);
                    this.sendAttachmentToChat(file, chatData);
                }

                if (res['type'] === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * res['loaded'] / res['total']);
                    this.loaderPercent$.next(percentDone)
                }

            }, err => {
                this.notiService.handleFullError(err);
            });
    }

    public sendAttachmentToChat(file, chatData) {
        const msg = this.createMessage('', chatData);
        msg['attachments'] = [file];

        const send = {
            message: '',
            attachments: [file]
        };

        if (this.replyMsg$.value) {
            send['reply_to'] = this.replyMsg$.value.id
        }

        if (this.wsService.connected$ && window.navigator.onLine) {
            this.sendChatSpecialMessage(chatData.documentEntity, chatData.documentId, this.auth.auth.apiKey, send)
                .pipe(takeUntil(this.resetUpload$))
                .subscribe((message: ChatMessage) => {
                    if (this.attachmentsDraft$.value) {
                        this.spliceLastMessage$.next(chatData);
                        this.attachmentsDraft$.next(null);
                        this.replyMsg$.next(null);
                    }
                });
        } else {
            this.saveMessage(msg, chatData);
            chatData['msg'] = msg;
            this.makeFakeMsg$.next(chatData);
        }
    }

    saveMessage(msg, chatData) {
        const saved = localStorage.getItem('savedMessages' + this.auth.auth.id + chatData.documentId);
        let messages = [];
        if (saved) {
            messages = JSON.parse(saved);
        }

        messages.unshift(msg);

        localStorage.setItem('savedMessages' + this.auth.auth.id + chatData.documentId, JSON.stringify(messages));
    }

    /** Создает из текста сообщение нужного для веб сокета формата */
    createMessage(message: string, chatData): any {
        const user = this.auth.currentUser$.value;
        const tags = this.generateTags(message);

        return {
            type: chatData.entity,
            entity: chatData.documentEntity + '=' + chatData.documentId,
            user_id: user.id,
            user_photo: user.photo,
            first_name: user.firstName,
            middle_name: user.middleName,
            last_name: user.lastName,
            message,
            tags: tags,
            msg_uid: hashCode(chatData.documentEntity + '=' + chatData.documentId + 'user id' + user.id + 'message' + message + 'date' + new Date()),
        };
    }

    generateTags(message) {
        const regexp = new RegExp(/(@\S+)|(\S+)'/g);
        const array = [...message.matchAll(regexp)];
        let tags = [];

        array.forEach(el => {
            if (this.testMemberLink(el[0])) {
                tags.push(this.testMemberLink(el[0]))
            }
        });

        return tags;
    }

    testMemberLink(link) {
        const list = this.members$.value;

        return list.find(el => el.link == link);
    }

    public getChatsRoomsByTimestamp(api_key, timestamp, count, searchStr = null, isUnread = false): Observable<any> {
        let search = ``;
        if (searchStr) {
            search = `&search=${searchStr}`;
        }
        let unread = ``;
        if (isUnread) {
            unread = `/unread`
        }
        return this.http.get(
            `${this.config.chatRooms}${unread}?time=${timestamp}&limit=${count}${search}`,
            {headers: {Authorization: api_key}}
        ).pipe(map(res => res['payload']));
    }

    public getChatHistory(id, api_key, limit = 0, type = null, offset = null,): Observable<any> {
        return this.http.get(
            `${this.config.chatHistory}?id=${id}` + (limit > 0 ? `&limit=${limit}` : ``) + '' +  (type ? `&type=${type}` : ``) + (offset ? `&offset=${offset}` : ``),
            {headers: {Authorization: api_key}}
        ).pipe(
            map(res => ({
                chat_users: res['chat_users'],
                messages: res['payload'],
                obj: res['related_object'],
                notify: res['notify']
            })
        ));
    }

    public getChatRoom(id, entity, api_key): Observable<any> {
        return this.http.get(
            `${this.config.chatRooms}/${entity}/${id}`,
            {headers: {Authorization: api_key}}
        ).pipe(map(res => res['payload']));
    }

    public deleteChatRoom(id, entity, api_key): Observable<any> {
        return this.http.delete(
            `${this.config.chatRooms}/${entity}/${id}`,
            {headers: {Authorization: api_key}}
        );
    }

    public sendChatMessage(entity, id, api_key, msg, tags, reply_to): Observable<any> {
        return this.http.post(
            `${this.config.sendMessage}${entity}/entity_id/${id}`,
            {message: msg, tags: tags, reply_to},
            {headers: {Authorization: api_key}}
        );
    }

    public sendChatSpecialMessage(entity, id, api_key, body): Observable<any> {
        return this.http.post(
            `${this.config.sendMessage}${entity}/entity_id/${id}`,
            body,
            {headers: {Authorization: api_key}}
        );
    }

    public editChatMessage(idMsg, api_key, msg, tags): Observable<any> {
        return  this.http.put(
            `${this.config.editChatMessage}/${idMsg}`,
            {message: msg, tags: tags},
            {headers: {Authorization: api_key}}
        ).pipe(map(res => res['payload']));
    }

    public deleteChatMessage(idMsg, api_key): Observable<any> {
        return this.http.delete(
            `${this.config.editChatMessage}/${idMsg}`,
            {headers: {Authorization: api_key}}
        );
    }

    public getMyCirclesApi(api_key): Observable<OrgUnitShortForNotifications[]> {
        return this.http.get(
            `${this.config.usersCirclesWithAdmin}`,
            {headers: {Authorization: api_key}}
        ).pipe(
            map((res: { circles: any[] }) => res && res.circles && res.circles.length && res.circles.map(el =>
                new OrgUnitShortForNotifications(el.id, el.unit.name)))
        );
    }

    public fetchMyCircles(api_key, currentCircleId) {
        this.getMyCirclesApi(api_key)
            .pipe(take(1))
            .subscribe(res => {
                const circlesListForSides: string[] = [];
                circlesListForSides.push(currentCircleId);
                res.forEach(el => circlesListForSides.push(el.id));
                this.myCirclesIds$.next(circlesListForSides);
            }, err => this.noti.handleFullError((err.error.message)));
    }
}
